<template>
  <div>
    <p class="text-center">
      <v-btn color="success" class="mr-4" @click="backPage"> Back </v-btn>
    </p>
    <v-row wrap>
      <v-col cols="12" lg="12">
        <personal-information />
      </v-col>
      <v-col cols="12" lg="6">
        <address-information />
      </v-col>
      <v-col cols="12" lg="6">
        <nominee-details />
      </v-col>
      <v-col cols="12" lg="6">
        <document-details />
      </v-col>
      <v-col cols="12" lg="6">
        <training-career />
      </v-col>
      <v-col cols="12" lg="6">
        <v-card elevation="4" outlined shaped>
          <v-card-title>
            <h3 class="text-dark"><b>JCI Career</b></h3>
          </v-card-title>
          <member-carrer :records="MemberId"></member-carrer>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card elevation="4" outlined shaped>
          <v-card-title>
            <h3 class="text-dark"><b>JCOM Career</b></h3>
          </v-card-title>
          <jcom-career :records="JcomDesignation"></jcom-career>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <foundation-contribution :titles="titles"></foundation-contribution>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card
          elevation="4"
          outlined
          shaped
          v-if="RoleId == 1 || RoleId == 2 || RoleId == 4"
        >
          <v-card-title>
            <h3 class="text-dark"><b>Contribution Paid details</b></h3>
          </v-card-title>
          <member-dues :records="dues"></member-dues>
        </v-card>
      </v-col>
    </v-row>

    <!-- <div class="row">
      <div class="col-lg-12">
        <v-card elevation="24" outlined shaped>
          <member-details :records="rows"></member-details>
        </v-card>
      </div>
      <div class="col-lg-6">
        <v-card elevation="24" outlined shaped>
          <v-card-title>Address</v-card-title>
          <member-address :records="address"></member-address>
        </v-card>
      </div>
    </div> -->
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import PersonalInformation from "@/view/pages/erp/members/member-individual/PersonalInformation";
import AddressInformation from "@/view/pages/erp/members/member-individual/AddressInformation";
import DocumentDetails from "@/view/pages/erp/members/member-individual/DocumentDetails";
import NomineeDetails from "@/view/pages/erp/members/member-individual/NomineeDetails";
import TrainingCareer from "@/view/pages/erp/members/member-individual/TrainingCareer";
import FoundationContribution from "@/view/pages/erp/members/member-individual/FoundationContribution";

import MemberDetails from "@/view/pages/erp/members/member-individual/MemberDetails.vue";
import MemberAddress from "@/view/pages/erp/members/member-individual/MemberAddress.vue";
import MemberCarrer from "@/view/pages/erp/members/member-individual/MemberCarrer.vue";
import MemberDues from "@/view/pages/erp/members/member-individual/MemberDues.vue";
import JcomCareer from "@/view/pages/erp/members/member-individual/JcomCareer.vue";

export default {
  name: "custom-page",
  components: {
    PersonalInformation,
    AddressInformation,
    DocumentDetails,
    NomineeDetails,
    TrainingCareer,
    FoundationContribution,

    MemberDetails,
    MemberAddress,
    MemberCarrer,
    MemberDues,
    JcomCareer,
  },
  computed: {
    ...mapGetters(["currentUserPhoto"]),
  },
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      ResultFlag: 0,

      tab: null,
      MemberId: "",
      address: {},
      JcomDesignation: [],
      contact: [],
      designation: [],
      dues: [],
      titles: [],

      rows: [],
      RoleId: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile 3" }]);
  },
  watch: {
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = Object.keys(rows).length;
      console.log("n1=" + n1 + ", rows=" + JSON.stringify(rows));
      if (n1 > 0) {
        this.ResultFlag = 1;
        this.address = rows.Address[0];
        this.contact = rows.Contact;
        this.designation = rows.OfficesHeld;
        this.dues = rows.Dues;
        this.titles = rows.Titles;
        this.JcomDesignation = rows.JcomDesignation;
      }
    },
  },
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      this.resetMessageTxt();

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 2,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.SuccessMessageTxtFlag = 1;
            thisIns.SuccessMessageTxt = output;
            thisIns.rows = records;
          } else {
            thisIns.ErrorMessageTxtFlag = 1;
            thisIns.ErrorMessageTxt = output;
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
  },
  beforeMount() {
    console.log(this.$route.query.id);
    this.MemberId = this.$route.query.id;

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? 0 : RoleId;

    console.log("token=" + token + ", RoleId=" + RoleId);
    this.RoleId = RoleId;
    this.refreshPageData();
    // this.$router.push("/access-denied");
  },
};
</script>
